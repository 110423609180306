/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: Poppins-ExtraBold;
  src: url("./static/fonts/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: Poppins-Regular;
  src: url("./static/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Poppins-BoldItalic;
  src: url("./static/fonts/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("./static/fonts/Poppins-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: break-word; /* Standard property */
  word-wrap: break-word; /* For older browsers */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cs-navbar {
  flex-shrink: 0;
  opacity: 0.96;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 152.98%);
  backdrop-filter: blur(50px);
  color: #8db0ba;
}

.cs-navbar-btn-selected {
  color: #0a789c;
  position: relative;
}

.cs-navbar-btn-selected::after {
  content: "";
  position: absolute;
  width: 40%;
  height: 3px;
  background-color: #bbdee8;
  left: 35%;
  top: 115%;
}

.cs-free-trial-btn {
  color: white;
  border-radius: 10px;
  background-color: black;
  margin-left: 10px;
  transition: all 0.6s ease-in-out;
}

.cs-free-trial-btn:hover {
  background-color: #e4ebf1;
  color: #0f97c3;
  transform: scale(1.1);
}

.cs-watch-demo-btn {
  color: black;
  border: solid 2px black;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
}

.cs-watch-demo-btn > i {
  color: #1aaede;
  transition: all 0.6s ease-in-out;
}

.cs-watch-demo-btn:hover {
  border-color: transparent;
  transform: scale(1.1);
  background-color: #e9faff;
  color: #0f97c3;
}

.cs-watch-demo-btn:hover .cs-watch-demo-icon {
  transform: rotate(-90deg);
  color: #0f97c3;
}

.dashboard {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 60%,
    rgba(0, 0, 0, 0) 75%
  );
}

.cs-hash {
  background: linear-gradient(157deg, #00c0ff 41.79%, #03efdf 71.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cs-card-bg {
  background-color: #d7ebf2;
}

.cs-card-heading {
  color: #003d53;
}

.cs-card-benefit-heading {
  color: #003d53;
}

.cs-card-benefit-description {
  color: #426774;
}

.cs-feature-card-bg {
  background-color: #d7ebf2;
}

.cs-feature-card-heading {
  color: #003d53;
}

.cs-feature-card-description {
  color: #426774;
}

.cs-dataviz-bg {
  background-color: #001e27;
}

.cs-dataviz-heading {
  background: linear-gradient(101deg, #54d6ff 35.15%, #24ede2 67.61%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.data-viz {
  overflow: hidden;
  white-space: nowrap;
}

.row1 {
  display: inline-flex;
  animation: 20s slideLeft infinite linear;
}

.row2 {
  display: inline-flex;
  animation: 20s slideRight infinite linear;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
}

.cs-blog-btn {
  background-color: #d7ebf2;
  color: #395661;
}

.blog-btn-selected {
  color: white;
  background-color: black;
}

.cs-blog-card-bg {
  background-color: #d7ebf2;
}

.cs-blog-card-heading {
  color: #003d53;
}

.cs-blog-card-date {
  color: #426774;
}

.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
}

.dash {
  width: 100%;
  height: 3px;
  background-color: #000;
}

.dash + .dash {
  margin-top: 3px;
}

.dropbtn {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropbtn:hover {
  background-color: #ddd;
}

.dropdown-content {
  position: absolute;
  z-index: 1000;
  background-color: white;
  width: 100%;
  padding: 0px;
}

.dropdown-content.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 1s ease;
  width: 100%;
}

.dropdown-content.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 1s ease;
}

.dropdown-content a {
  display: block;
  /* padding: 10px; */
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
  text-align: center;
}

.dropdown-content a:hover {
  background-color: #dcdcdc;
}

.dropdown-content a.selected {
  background-color: #95bce6;
  color: #fff;
  border: 1px solid #95bce6;
}

.free-trial-heading {
  background: linear-gradient(
    112deg,
    #fff 46.42%,
    rgba(255, 255, 255, 0) 151.56%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animation-end {
  border-radius: 0px 0px 17px 17px;
  border: 3px solid #05ffff;
  background: linear-gradient(93deg, #21a6a6 15.91%, #1b6db7 69.23%);
}

#report-ready-text {
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
}

.tick-circle {
  background: linear-gradient(142deg, #053462 18.69%, #01c2ff 95.37%);
  stroke-width: 12px;
  filter: drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 100%;
  border: 8px solid white;
}

.tick-circle > i {
  transform: scale(3);
}

#chain-connect {
  width: 1vw;
  height: 3.5vw;
  background-color: transparent;
  border-width: 3px;
  border-radius: 10px;
  border-color: #af9aff #af9aff #af9aff transparent;
  position: absolute;
  right: 5px;
  top: 4.5vw;
  z-index: -1;
  opacity: 0;
}

@keyframes chainConnectAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#chain-connect.animate {
  animation: chainConnectAnimation 1s ease forwards;
}

@keyframes bar1Animation {
  from {
    height: 10vh;
  }
  to {
    height: 6vh;
  }
}

#bar1.animate {
  animation: bar1Animation 1s ease forwards;
}

@keyframes bar2Animation {
  from {
    height: 3vh;
  }
  to {
    height: 8vh;
  }
}

#bar2.animate {
  animation: bar2Animation 1s ease forwards;
}

@keyframes bar3Animation {
  from {
    height: 7vh;
  }
  to {
    height: 4vh;
  }
}

#bar3.animate {
  animation: bar3Animation 1s ease forwards;
}

#complete-thought-tick {
  opacity: 0;
}

@keyframes completeThoughtTick {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#complete-thought-tick.animate {
  animation: completeThoughtTick 1s ease forwards;
}

#report-ready-bar {
  left: 2vw6+51;
}

@keyframes reportReadyBar {
  from {
    left: 2vw;
  }
  to {
    left: 12vw;
  }
}

#report-ready-bar.animate {
  animation: reportReadyBar 1s ease forwards;
}

#report-ready-text {
  width: 0;
}

@keyframes reportReadyText {
  from {
    width: 0;
  }
  to {
    width: 40%;
  }
}

#report-ready-text.animate {
  animation: reportReadyText 1s ease forwards;
}

#report-ready-check {
  opacity: 0;
}

@keyframes reportReadyCheck {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#report-ready-check.animate {
  animation: reportReadyCheck 1s ease forwards;
}

.footer-block {
  width: 100%;
  color: #fff;
  background-color: #18181b;
  background-image: url(https://uploads-ssl.webflow.com/6448f35…/6448f35…_Footer%20Pattern.svg),
    url(https://uploads-ssl.webflow.com/6448f35…/6448f35…_CTA%20Background.svg);
  background-position: 0 100%, 100% 0;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto;
  /* border-radius: 16px; */
  margin-left: auto;
  margin-right: auto;

  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  padding: 30px;
  /* When the viewport is 850px or more */
  @media (min-width: 850px) {
    padding: 64px;
    border-radius: 16px;
  }
}

.container-small {
  width: 100%;
  position: relative;
}

.footer-grid {
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  display: grid;
}
.footer-logo {
  width: 100%;
  margin-bottom: 10px;
  object-fit: contain;
  text-align: left;
}

.grid-component {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.footer-link-grid {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 1fr;
}

.footer-link-column {
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.footer-title {
  color: #e4e4e7;
  letter-spacing: -0.011em;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
}

.footer-social-links {
  grid-column-gap: 16px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.footer-social-link {
  width: 40px;
  height: 40px;
  color: #18181b;
  background-color: #7a70ff;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer-divider {
  width: 100%;
  height: 1px;
  background-color: #3a3a41;
  margin-top: 48px;
  margin-bottom: 32px;
}

.footer-bottom {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 680px) {
  .footer-grid {
    grid-column-gap: 72px;
    grid-template-columns: 1fr 0.5fr;
  }
  .footer-social-links {
    justify-content: flex-start;
  }
  .footer-bottom {
    justify-content: flex-start;
  }
}

.cs-get-trial-btn {
  background: linear-gradient(100deg, #000 42.45%, #00c2ff 122.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: white;
}
